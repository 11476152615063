import { render, staticRenderFns } from "./Staff.vue?vue&type=template&id=525a8f76&scoped=true&"
import script from "./Staff.vue?vue&type=script&lang=js&"
export * from "./Staff.vue?vue&type=script&lang=js&"
import style0 from "./Staff.vue?vue&type=style&index=0&id=525a8f76&prod&scoped=true&lang=css&"
import style1 from "./Staff.vue?vue&type=style&index=1&id=525a8f76&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525a8f76",
  null
  
)

export default component.exports